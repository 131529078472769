import React, { useState, useCallback }  from "react";
import { motion, AnimatePresence } from "framer-motion";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import ContactForm from "components/ContactForm";
import CardAuto from "components/CardAuto/CardAuto";

//import galeria1 from "../assets/img/descarga.jpg";
//import galeria2 from "../assets/img/descarga1.jpg";
//import galeria3 from "../assets/img/descarga2.jpg";
//import galeria4 from "../assets/img/descarga3.jpg";
//import video1 from "../assets/video/video1.webm";

import galeria1 from "../assets/img/descarga.jpg";
import galeria2 from "../assets/img/descarga1.jpg";
import galeria3 from "../assets/img/mailFondo.jpg";
import galeria4 from "../assets/img/descarga2.jpg";
import galeria5 from "../assets/img/descarga5.png";
import galeria6 from "../assets/img/descarga4.png";

import etiosXLS from "../assets/img/etiosXLS.png"
import golTrend from "../assets/img/golTrend.png"
import prisma from "../assets/img/prisma.png"
import voyage from "../assets/img/voyage.png"
import onix from "../assets/img/onix.png"
import joy from "../assets/img/joy.png"
import cronos from "../assets/img/cronos.png"

//import principal from "../assets/img/turismo_interlagos.webp";
import principal from "../assets/img/descarga3.jpg";


import { MdCarRental } from "react-icons/md";
import { BiSolidBabyCarriage } from "react-icons/bi";

// SWIPER //
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, A11y } from 'swiper';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

//import './landing.css'; 

// FIN SWIPER //

export default function Landing() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = [
    {
      src: galeria1,
      alt: "Cancha de bochas, Valle de la Luna",
      mapa: "https://www.google.com/maps/place/Primera+Estación+-+El+Gusano/@-30.1594966,-67.8524724,17z/data=!3m1!4b1!4m6!3m5!1s0x9682ed079c870971:0xb16e303f4b80cb8a!8m2!3d-30.1594966!4d-67.8498975!16s%2Fg%2F11f5v5p_cj?entry=ttu"
    },
    {
      src: galeria2,
      alt: "Carrovelismo, Pampa el Leoncito",
      mapa: "https://www.google.com/maps/place/Pampa+del+Leoncito/@-31.7002707,-69.3171616,10z/data=!4m10!1m2!2m1!1spampa+el+leoncito!3m6!1s0x9686ee09c1de0885:0x9b140ccac17214b8!8m2!3d-31.8189817!4d-69.4604108!15sChFwYW1wYSBlbCBsZW9uY2l0b5IBEnRvdXJpc3RfYXR0cmFjdGlvbuABAA!16s%2Fg%2F1232pjn23?entry=ttu"
    },
    {
      src: galeria3,
      alt: "Ischigualasto - Parque triásico",
      mapa: "https://www.google.com/maps/place/Cam.+Parque+Triásico+Ischigualasto,+San+Juan/@-30.1146128,-67.859676,17z/data=!3m1!4b1!4m6!3m5!1s0x9682ec0d6f787ff9:0x85a16cd8b6bbb160!8m2!3d-30.1146128!4d-67.8571011!16s%2Fg%2F11bc8dd1fp?entry=ttu"
    },
    {
      src: galeria4,
      alt: "Parque Nacional El Leoncito",
      mapa: "https://www.google.com/maps/place/Parque+Nacional+El+Leoncito/@-31.7002707,-69.3171616,10z/data=!4m10!1m2!2m1!1spampa+el+leoncito!3m6!1s0x9686ebfb76fc6fbb:0x927d0dcaa1badda8!8m2!3d-31.7974153!4d-69.373951!15sChFwYW1wYSBlbCBsZW9uY2l0b5IBDW5hdGlvbmFsX3BhcmvgAQA!16zL20vMDdiMWY5?entry=ttu"
    },
    {
      src: galeria5,
      alt: "Dique Punta Negra",
      mapa: "https://www.google.com/maps/place/Represa+Punta+Negra,+San+Juan/@-31.519211,-68.8203809,17z/data=!3m1!4b1!4m6!3m5!1s0x96814c1bec2e6b6b:0xeb40edc83d76af7f!8m2!3d-31.519211!4d-68.817806!16s%2Fm%2F010gkm1l?entry=ttu"
    },
    {
      src: galeria6,
      alt: "Dique Cuesta del Viento",
      mapa: "https://www.google.com/maps/place/Embalse+Cuesta+del+Viento/@-30.1833324,-69.0718169,16z/data=!3m1!4b1!4m6!3m5!1s0x9684a365dda86b77:0xc85819117eac932!8m2!3d-30.183333!4d-69.066667!16s%2Fg%2F121p7mb4?entry=ttu"
    },
  ];

  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-48 flex content-center items-end justify-center "
            style={{
              minHeight: "75vh"
            }}>
          <div className="absolute object-center top-0 w-full h-full bg-center bg-cover"
              style={{
                //backgroundImage: "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')"
                backgroundImage: `url(${principal})`,
              }}>
            <span id="blackOverlay" className="w-full h-full absolute opacity-25 bg-black"></span>
            
          </div>
          <div className="container relative mx-auto pt-24">
              <div className="items-center flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                  <div className="pr-8">
                    <h1 className="text-white font-semibold text-5xl">
                      Drive It Rent a Car
                    </h1>
                  </div>
                </div>

              </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 98"
              x="0"
              y="0"
            >
              <polygon
                //className="text-gray-300 fill-current" abajo es bg-gray-300
                className="text-amber-500 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-amber-500 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Calidad</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Contamos con una moderna flota para brindarte la mejor experiencia en el alquiler de tu vehículo
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                    <MdCarRental size={70}/>
                    </div>
                    <h6 className="text-xl font-semibold">
                    Alquiler
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                    De vehículos de distintas categorias con entregas en distintos puntos de la provincia. 
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                    <BiSolidBabyCarriage size={70}/>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Adicionales
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Alquiler de silla de bebé, GPS, cadenas para nieve, porta esquíes y más.
                      Entrega en radio céntrico o puntos de entrega preestablecidos.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>


        <section className="pb-20 bg-white -mt-24">
          <div className="container mx-auto px-8 py-4">
          <div class="flex flex-wrap justify-between ">
            <div class="mt-2">
              <CardAuto 
                image={etiosXLS}
                nombre = {"Toyota Etios XLS" }
                categoria = {"Categoria: N - Pick Up 4X4)"}
                puertas = {4}
                personas = {5}
                valijas = {2}
                transmision = {"Automática"}
                detalle = {"ABS, airbag, dirección hidráulica, aire acondicionado"}
                consumoPromedio = {"11 KM por litro"}
                />
            </div>
            <div class="mt-2 mr-2 ">
              <CardAuto 
                image={etiosXLS}
                nombre = {"Toyota Etios XLS Manual" }
                categoria = {"Categoria: N - Pick Up 4X4)"}
                puertas = {4}
                personas = {5}
                valijas = {2}
                transmision = {"Manual 6 velocidades"}
                detalle = {"ABS, airbag, dirección hidráulica, aire acondicionado"}
                consumoPromedio = {"11 KM por litro"}
                />
            </div>
            <div class="mt-2 mr-2 ">
              <CardAuto 
                image={golTrend}
                nombre = {"Volkswagen Gol Trend" }
                categoria = {"Grupo: C - Intermedio Manual ( ICMR )"}
                puertas = {4}
                personas = {4}
                valijas = {2}
                transmision = {"Manual 5 velocidades"}
                detalle = {"Aire Acondicionado,ABS, airbag, dirección hidráulica"}
                consumoPromedio = {"12 KM por litro"}
                />
            </div>
            <div class="mt-2 mr-2 ">
              <CardAuto 
                image={prisma}
                nombre = {"Chevrolet Prisma" }
                categoria = {"Categoria W"}
                puertas = {4}
                personas = {5}
                valijas = {2}
                transmision = {"Manual 5 velocidades"}
                detalle = {"Aire Acondicionado,ABS, Airbag, dirección hidráulica"}
                consumoPromedio = {"11 KM por litro"}
                />
            </div>
            <div class="mt-2 mr-2 ">
              <CardAuto 
                image={voyage}
                nombre = {"Volkswagen Voyage" }
                categoria = {"Categoria L"}
                puertas = {4}
                personas = {5}
                valijas = {2}
                transmision = {"Manual 5 velocidades"}
                detalle = {"Aire Acondicionado, ABS, Airbag, dirección hidráulica."}
                consumoPromedio = {"11 KM por litro"}
                />
            </div>
            <div class="mt-2 mr-2 ">
              <CardAuto 
                image={onix}
                nombre = {"Chevrolet Onix" }
                categoria = {"Categoria L"}
                puertas = {4}
                personas = {5}
                valijas = {2}
                transmision = {"Manual 5 velocidades"}
                detalle = {"Aire Acondicionado, ABS, Airbag, dirección hidráulica."}
                consumoPromedio = {"11 KM por litro"}
                />
            </div>
            <div class="mt-2 mr-2 ">
              <CardAuto 
                image={joy}
                nombre = {"Chevrolet Joy"}
                categoria = {"Categoria L"}
                puertas = {4}
                personas = {5}
                valijas = {2}
                transmision = {"Manual 5 velocidades"}
                detalle = {"Aire Acondicionado, ABS, Airbag, dirección hidráulica."}
                consumoPromedio = {"11 KM por litro"}
                />
            </div>
            <div class="mt-2 mr-2 ">
              <CardAuto 
                image={cronos}
                nombre = {"Fiat Cronos" }
                categoria = {"Categoria L"}
                puertas = {4}
                personas = {5}
                valijas = {2}
                transmision = {"Automática CVT"}
                detalle = {"Aire Acondicionado, ABS, Airbag, dirección hidráulica."}
                consumoPromedio = {"11 KM por litro"}
                />
            </div>
          </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
              </div>
              <Swiper
              className="swiper-container"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                639: {
                  slidesPerView: 3,
                },
                768:{
                  slidesPerView:2,
                  spaceBetween:50
                }
              }}
               modules={[Navigation, Pagination, A11y]}
               centeredSlides = "true"
               centerInsufficientSlides = "true"
               centeredSlidesBounds = "true"
               
               slidesPerView={3}spaceBetween={50}
               navigation
               onSwiper={(swiper) => console.log(swiper)}
               onSlideChange={() => console.log('slide change')}
                 >
                {
                    photos.map((i, el) => {
                        if(i.tipo!==undefined){
                          return <SwiperSlide><video width="950" height="800" controls >
                            <source src={i.src} type="video/mp4"/>
                            </video></SwiperSlide>;
                        }
                        else{  
                          return <SwiperSlide><img src={i.src}/><b>{i.alt}</b>. <a href={i.mapa}> <b><u>Como Llegar</u></b></a></SwiperSlide>;
                        }
                    })
                }
              </Swiper>
              <div>
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
          
              </div>
            </div>
          </div>
        </section>
        

        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <h2 className="text-4xl font-semibold text-white leading-relaxed">
                    Quienes Somos
              </h2>
          <section className="relative block py-12 lg:pt-0 bg-gray-900">
            <div className="container mx-auto px-4">
              <div className="flex flex-wrap justify-center  ">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                    <div className="flex-auto p-5 lg:p-10">
                      <p>
                        <b>Drive It</b> es una empresa familiar que nace en el 2023 con el propósito de brindarte una excelente experiencia en el alquiler de tu vehículo principalmente en San Juan, abarcando también todo Cuyo, ya sea que viajes por negocios y/o vacaciones.
                      </p>
                      <p>
                        Nos adaptamos a las necesidades de nuestros clientes, brindándote calidad en el servicio, un vehículo confortable que cumple con todas las normas de seguridad vigentes y de último modelo.
                      </p>
                      <p>
                        Es por esto que decidimos crear <b>Drive It</b>. 
                      </p>
                      <p>
                        ¡Nuestra moderna y variada flota está esperando por vos!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white leading-relaxed">
                  Puntos de Entrega
                </h2>
                <div className="" class="map-responsive" style={{"overflow":"hidden","padding-bottom":"56.25%","position":"relative","height":0}}>
                  <iframe src="https://www.google.com/maps/d/embed?mid=1SIBj3Upop6sOvX9QwAAQnfhJMh2UI40&ehbc=2E312F&ll=-31.55431205523126%2C-68.47383445&z=15&noprof=1" width="400" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{"left":0,"top":0,"height":"100%","width":"100%","position":"absolute"}}></iframe>
                </div>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-white">
                <div>
                <h6 className="text-3xl font-semibold text-white leading-relaxed">
                  Acceso directo a mapas
                </h6>
                  <ul>
                    <li>
                      <a href="https://www.google.com/maps/place/Plaza+25+de+Mayo/@-31.5375528,-68.5166752,15.37z/data=!4m6!3m5!1s0x968140281b059031:0xbdafc7e302f74c9a!8m2!3d-31.5373564!4d-68.5251884!16s%2Fg%2F123266t3?entry=ttu" target="_blank">Plaza 25 de Mayo</a>
                    </li>
                    <li>
                      <a href="https://www.google.com/maps/place/Aeropuerto+Internacional+Domingo+Faustino+Sarmiento+-+San+juan/@-31.5714039,-68.4263423,15.65z/data=!3m1!5s0x9681404eacefc3d9:0x957e4d0b24c84dec!4m17!1m10!3m9!1s0x96814049784b18d1:0x8a8b0aedecb834f1!2sDel+Bono+Park+Hotel+Spa+%26+Casino!5m2!4m1!1i2!8m2!3d-31.5382567!4d-68.5545717!16s%2Fg%2F1tfmdfvp!3m5!1s0x968113c8cd4fb44d:0x635a174732ca0543!8m2!3d-31.5713459!4d-68.4225713!16s%2Fm%2F03whpd2?entry=ttu" target="_blank">Aeropuerto Internacional Domingo Faustino Sarmiento</a>
                    </li>
                    <li>
                      <a href="https://www.google.com/maps?cid=10723441143342787390" target="_blank">Terminal de Ómnibus de San Juan</a>
                    </li>
                  </ul>
                </div>

                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
              <a href="https://www.instagram.com/driveit_rentacar/">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fab fa-instagram text-xl"></i>
                </div>
                </a> 
                <a href="https://www.instagram.com/driveit_rentacar/">
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Instagram
                </h6>
                </a>
                <a href="https://www.instagram.com/driveit_rentacar/">
                <p className="mt-2 mb-4 text-white">
                  Comunicate con nosotros
                </p>
                </a>
              </div>
            </div>
          </div>
        </section>
        <div></div>
        <section className="relative block py-24 lg:pt-0 bg-gray-900">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-32">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                  <div className="flex-auto p-5 lg:p-10">
                  <ContactForm/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()}{" "}Drive it Rent a Car{" "}
            </div>
            <div className="text-sm text-gray-600 font-semibold py-1">
              Desarrollado por <a href={"https://wa.me/5492645872243?text=Hola,%20quisiera%20hacer%20una%20consulta."}> <b><u>EtereoSoftware</u></b></a>
            </div>
          </div>
        </div>
      </main>
      
      
      {/* This is a JSX comment 
      <Footer />
      */} 
    </>
  );
}
