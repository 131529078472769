import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

//import 'dotenv/config';

import "@fortawesome/fontawesome-free/css/all.min.css";

import Landing from "views/Landing.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import { FcFaWhatsappAbout, FcBusinessman, FcCamera, FcFullTrash } from "react-icons/fc";
//import { FaWhatsapp,  } from "react-icons/fa";

import Fab from "./components/FAB";

const actions = [
 
];


ReactDOM.render(
  
  <BrowserRouter>
   <Fab actions={actions} />
  <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />
    <Switch>
      <Route path='/' component={Landing} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
