import React from "react";
import axios from "axios";

import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

//require('dotenv').config();

export default function ContactForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const limpiarCampos = () => {
        let inputNombre = document.getElementById("Nombre");
        inputNombre.value="";
        let inputEmail = document.getElementById("Email");
        inputEmail.value="";
        let inputTelefono = document.getElementById("Telefono");
        inputTelefono.value="";
        let inputMensaje = document.getElementById("Mensaje");
        inputMensaje.value="";
    }

    const onSubmit  = async (data) => {
        //console.log(data);
        try{
            const resultado = await axios.post("https://driveitrentacar.com.ar:9003/sendmail/" ,{email:data.Email,nombre: data.Nombre,mensaje: data.Mensaje, telefono: data.Telefono});
            if(resultado.status===200){
                console.log("Exitoso");
                toast.success(<div>Email enviado correctamente</div>, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    whiteSpace: "pre-line",
                    theme:"light"
                    });
                    limpiarCampos();
            }
            if(resultado.status===400){
                console.log("bad request");
                toast.error(<div>Error al enviar el email. Bad request</div>, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    whiteSpace: "pre-line",
                    theme:"light"
                    });
            }
            if(resultado.status===500){
                console.log("error servidor");
                toast.error(<div>Error al enviar el email, intente nuevamente</div>, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    whiteSpace: "pre-line",
                    theme:"light"
                    });
            }
        }
        catch(error){
            console.log(error);
        }
       
    }
    //console.log(errors);

    return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="text-2xl font-semibold">
                    Envianos un Email
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                    Complete este formulario y lo contactaremos a la brevedad
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                    <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="full-name"
                    >
                        Nombre
                    </label>  
                    <input type="text" placeholder="Nombre" id="Nombre" {...register("Nombre", {required: true, maxLength: 80})}
                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    
                    />
                    {errors.Nombre?.type === 'required' && 
                    <span style={{ color: "red" }}>
                    Campo Requerido
                    </span>}
                    {errors.Nombre?.type === 'maxLength' &&
                    <span style={{ color: "red" }}>
                    El nombre debe tener menos de 80 caracteres
                    </span>}
                    </div>
                    <div className="relative w-full mb-3">
                    <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="email"
                    >
                        Email
                    </label>
                    <input type="email" placeholder="Email" id="Email" {...register("Email", {required: true, pattern: /^\S+@\S+$/i,  message: "invalid email address"})}
                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    />
                    {errors.Email?.type === 'required' && 
                    <span style={{ color: "red" }}>
                    Campo Requerido
                    </span>}
                    {errors.Email?.message === 'invalid email address' &&
                    <span style={{ color: "red" }}>
                    Email inválido
                    </span>}
                    </div>
                    <div className="relative w-full mb-3">
                    <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="message"
                    >
                        Teléfono
                    </label>
                    <input type="number" placeholder="Teléfono" id="Telefono" {...register("Telefono", {required: true, maxLength: 20, pattern: "[0-9]{9}"})}
                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" />
                    </div>
                    {errors.Telefono?.type === 'required' && 
                    <span style={{ color: "red" }}>
                    Campo Requerido
                    </span>}
                    {errors.Telefono?.type === 'maxLength' &&
                    <span style={{ color: "red" }}>
                    El Teléfono debe tener un máximo de 14 caracteres
                    </span>}
                    <div className="relative w-full mb-3">
                    <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="message"
                    >
                        Mensaje
                    </label>
                        <textarea {...register("Mensaje", {required: true})} 
                        className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" 
                        rows="4"
                        cols="80"
                        placeholder="Escriba el mensaje..."
                        id="Mensaje"
                        />   
                        {errors.Mensaje?.type === 'required' && 
                    <span style={{ color: "red" }}>
                    Campo Requerido
                    </span>}    
                    </div>
                    <div className="text-center mt-6">
                    <input type="submit" value="Enviar Mensaje" className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        style={{ transition: "all .15s ease" }}
                        />
                    </div>
    </form>
)
}
