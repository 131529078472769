import React , { useState }from "react";
import {  motion } from "framer-motion" // not "framer-motion/dist/framer-motion"
import './styles.css'; // Tell webpack that Button.js uses these styles

import caja from "./assets/gearbox.png";
import maleta from "./assets/maleta.png";
import personas from "./assets/personas.png";
import wsp from "./assets/whatsapp.png";
import consumo from "./assets/consumo.png";

export default function CardAuto({image,nombre, categoria, puertas, personas, valijas, transmision, detalle, consumoPromedio}) {

  console.log(motion);
  const [flip, setFlip] = useState(true);
    return (
      
      <div className="flex">
      
       <motion.div
        style={{ width: "20rem", height: "24rem" }}
        transition={{ duration: 0.7 }}
        animate={{ rotateY: flip ? 0 : 180 }}
      >
     
        <motion.div
          transition={{ duration: 0.7 }}
          animate={{ rotateY: flip ? 0 : 180 }}
          className="Card"
        >
          
          <motion.div
            transition={{ duration: 0.7 }}
            animate={{ rotateY: flip ? 0 : 180 }}
            className="front"
          >
            <div className="">
              <img class="w-full" src={image} alt="Vehículo" style={{width: "340px", height: "180px"}}/>
            </div>
        <div class="px-8 py-4">
          <div class="font-bold text-xl mb-2 text-left">{nombre}</div>
          {/*  <div class="text-sm font-semibold text-gray-400 mr-2 mb-2 text-left">{categoria}</div>*/}
        </div>
         {/*
        <div class="grid justify-items-center py-4">
          <div>
            {/*
            <a href={"https://wa.me/5492644861090?text=Hola,%20quisiera%20hacer%20una%20consulta%20sobre%20el%20vehículo%20"+nombre+"%20con%20transimisión%20"+transmision+"."} style={{ borderRadius: "4px", padding: "12px 48px",}} >
                <img src={wsp}/>
            </a>
            
            <button onClick={() => setFlip((prevState) => !prevState)} style={{ borderRadius: "4px", padding: "12px 48px"}}>
              <b><i class="fa fa-plus-square" aria-hidden="true"></i> Info</b>
            </button>
            </div>
          </div>
          */}
          <div class="grid justify-items-end mt-16">
          <div>
           <button onClick={() => setFlip((prevState) => !prevState)} style={{ borderRadius: "4px", padding: "12px 48px"}}>
              <b><i class="fa fa-plus-square" aria-hidden="true"></i> Info</b>
            </button>
            </div>
          </div>
        </motion.div>
          <motion.div
          
            initial={{ x: 0, y:-340, rotateY: 180}}
            animate={{ rotateY: flip ? 180 : 0 }}
            // style={{ display: flip ? "none" : "block" }}
            transition={{ duration: 0.7 }}
            className="back flex"
          >
            <div className="atras">
            <div class="grid justify-items-end ">
                <button onClick={() => setFlip((prevState) => !prevState)} style={{borderRadius: "4px", padding: "2px 24px"}}>
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
            </div>
              <div class="px-8">
                <table >
                  <tbody>
                    <tr>
                      <td> 
                        <div className="text-black p-3 text-center items-center justify-center">
                          {/*  <i class="fa fa-door-closed"></i> Puertas: {puertas} / <i class="fa fa-male" aria-hidden="true"></i> Personas: {personas} */}
                          <i class="fa fa-male" aria-hidden="true"></i> Personas: <b>{personas}</b>  / <i class="fa fa-suitcase" aria-hidden="true"></i> Valijas: <b>{valijas}</b>
                        </div>
                      </td> 
                    </tr>
                    <tr>
                      <td>
                      <div className="text-black p-3 text-center items-center justify-center">
                        Tipo de transmisión: <b>{transmision}</b>
                      </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                      <div class="grid justify-items-center">
                        Autonomia: <b>{consumoPromedio}</b>
                      </div>
                      </td>
                    </tr>
                    <tr>
                      <div className="text-black p-3 text-center items-center justify-center">
                          {detalle}
                      </div>
                    </tr>
                  </tbody>
                </table>
                <div class="grid justify-items-center">
                <a href={"https://wa.me/5492644861090?text=Hola,%20quisiera%20hacer%20una%20consulta%20sobre%20el%20vehículo%20"+nombre+"%20con%20transimisión%20"+transmision+"."} style={{ borderRadius: "4px", padding: "12px 48px",}} >
                    <img src={wsp}/>
                </a>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
)
}
