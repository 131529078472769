import { useState } from "react";
import cn from "classnames";
import { FaWhatsapp,  } from "react-icons/fa";

import "./styles.scss";

const FAB = ({ actions }) => {
  const [open, setOpen] = useState(false);

  const mouseEnter = () => setOpen(true);

  const mouseLeave = () => setOpen(false);

  return (
    <a href={"https://wa.me/5492644861090?text=Hola,%20quisiera%20hacer%20una%20consulta."}>
      <ul
        className={cn("fab-container", { open })}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        <li className="fab-button">
          <FaWhatsapp />
        </li>
        {actions.map((action, index) => (
          <li
            style={{ transitionDelay: `${index * 25}ms` }}
            className={cn("fab-action", { open })}
            key={action.label}
          >
            {action.icon}
            <span className="tooltip">{action.label}</span>
          </li>
          
        ))}
      </ul>
    </a>
  );
};

export default FAB;
